















import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import HookRule from './hookRule.vue'
import SensitiveSetting from './sensitiveManage.vue'
@Component({
  name: 'HookRuleIndex',
  components: {
    HookRule,
    SensitiveSetting
  },
})
export default class ChangePassword extends VueBase {
  private activeVal: string = 'settingSensitive'
}
