








































































































































































import VueBase from '@/VueBase'
import { Component, Watch } from 'vue-property-decorator'
import { StrategyListObj } from '@/views/setting/types'
import SensitiveDialog from './sensitiveDialog.vue'
import TestSensitiveDialog from './testSensitiveDialog.vue'

@Component({
  name: 'sensitiveManage',
  components: {
    SensitiveDialog,
    TestSensitiveDialog,
  },
})
export default class sensitiveManage extends VueBase {
  private testSensitiveDialogVisible: boolean = false
  private closeTestDialog() {
    this.testSensitiveDialogVisible = false
  }
  private closeDialog() {
    this.sensitiveDialog = false
  }

  private befortCloseDialog() {
    this.sensitiveValueId = ''
  }
  @Watch('sensitiveDialog', { deep: true, immediate: true })
  warningCloseDialog(newVal: any, oldVal: any) {
    if (!newVal) {
      this.sensitiveValueId = ''
      ;(this.$refs.sensitiveDialogComp as any)?.$refs.ruleForm.resetFields()
    }
  }

  private addSensitiveDialog() {
    this.sensitiveDialog = true
    // todo 清空form表单
    // this.$nextTick(() => {
    //   (this.$refs.sensitiveDialogComp as any)?.$refs.ruleForm.resetFields()
    // })
  }

  private testSensitiveDialog() {
    // todo 策略测试弹窗
    this.testSensitiveDialogVisible = true
  }
  private sensitiveDialog: boolean = false
  private sensitiveValueId: any = ''
  private cancelDialog() {
    this.sensitiveDialog = false
    // todo 清空form表单
  }
  // 新增/编辑策略规则
  private async saveSensitiveType() {
    this.sensitiveDialog = false
  }
  private goTo() {
    this.$router.push('/policyManage/hookRule')
  }

  private activeVal: string = 'privateInfo'
  private tableData: Array<StrategyListObj> = []
  private pattern_type = []
  private pattern_type_map = {}
  private page = 1
  private page_size = 20
  private total = 0
  private name = ''
  private resetData() {
    this.name = ''
    this.page = 1
    this.getTableData()
  }
  private async stateChange(row: any) {
    this.loadingStart()
    const res = await this.services.setting.update_sensitive_info_rule({
      ...row,
      ...{ status: row.status === 0 ? 1 : 0 },
    })
    this.loadingDone()
    if (res.status === 201) {
      this.$message.success('状态变更成功')
      this.getTableData()
    } else {
      this.$message.error('状态变更失败，请重试')
    }
  }

  private handleSizeChange(val: number) {
    this.page_size = val
    this.getTableData()
  }

  private handleCurrentChange(val: number) {
    this.page = val
    this.getTableData()
  }

  async created() {
    this.loadingStart()
    await this.getPatternType()
    await this.getTableData()
    this.loadingDone()
  }
  get userInfo(): { username: string; role: number } {
    return this.$store.getters.userInfo
  }

  private async getPatternType() {
    const res = await this.services.setting.pattern_type()
    if (res.status === 201) {
      this.pattern_type = res.data
      this.pattern_type.forEach((item: any) => {
        this.$set(this.pattern_type_map, item.id, {
          name: item.name,
          url: item.url,
        })
      })
    } else {
      this.$message.error(res.msg)
    }
  }
  private async getTableData() {
    this.loadingStart()
    const { status, msg, data, page } =
      await this.services.setting.get_sensitive_info_rule({
        page: this.page,
        page_size: this.page_size,
        name: this.name,
      })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    if (data.length === 0 && this.page > 1) {
      this.page--
      await this.getTableData()
      return
    }
    const rebuildData: any = []
    data.map((item: any, index: number) => {
      rebuildData.push({
        ...item,
        no: index + 1,
      })
    })
    this.tableData = rebuildData
    this.total = page.alltotal
  }
  private sensitiveDialogAdd() {
    this.$router.push({ name: 'sensitive' })
  }
  private sensitiveDialogEdit(row: any) {
    this.$nextTick(() => {
      this.sensitiveValueId = Number(row.id)
      if (row.system_type != 1) {
        // 编辑
        this.sensitiveDialog = true
      } else {
        // 查看详情
        this.sensitiveDialog = true
      }
    })
    // this.$router.push({
    //   name: 'sensitive',
    //   query: {
    //     id: row.id,
    //   },
    // })
  }
  private async sensitiveDialogDelete(row: any) {
    this.$msgbox({
      showCancelButton: true,
      cancelButtonText: '取消',
      confirmButtonText: '删除',
      cancelButtonClass: 'cancelButtonClass',
      confirmButtonClass: 'delete-btn',
      showClose: false,
      dangerouslyUseHTMLString: true,
      message: `
        <div class="title">
          <i class="el-icon-warning icon"></i>
          确定删除当前策略?
        </div>
        <div class="tip">
          删除后不可恢复，请谨慎操作
        </div>
      `,
    }).then(async () => {
      const res = await this.services.setting.delete_sensitive_info_rule_one({
        id: row.id,
      })
      if (res.status === 201) {
        this.$message.success('匹配规则删除成功')
        this.getTableData()
      } else {
        this.$message.error('匹配规则删除失败，请重试')
      }
    })
  }
}
