




















































import VueBase from '@/VueBase'
import { Component, Prop, Emit, Watch } from 'vue-property-decorator'

@Component({ name: 'testSensitiveDialog' })
export default class sensitiveManage extends VueBase {
  @Emit('closeDialog')
  private closeDialog() {
    ;(this.$refs.ruleForm as any)?.resetFields()
  }

  async created() {
    this.loadingStart()
    await this.getPatternType()
    this.loadingDone()
  }
  private pattern_type = []
  private strategyData = []
  private pattern_type_map = {}
  private sensitiveForm: any = {}
  private validatioData = ''
  private rules = {
    strategy_id: [
      {
        required: true,
        message: this.$t('views.sensitiveManage.nameR') as string,
        trigger: 'blur',
      },
    ],
    pattern_type_id: [
      {
        required: true,
        message: this.$t('views.sensitiveManage.detailR') as string,
        trigger: 'blur',
      },
    ],
    pattern: [
      {
        required: true,
        message: this.$t('views.sensitiveManage.fixR') as string,
        trigger: 'blur',
      },
    ],
  }

  private async regexValidation() {
    const form: any = this.$refs.ruleForm
    form.validate(async (valid: boolean) => {
      if (valid) {
        this.loadingStart()
        const res = await this.services.setting.regex_validation({
          // url: this.pattern_type_map[this.sensitiveForm.pattern_type_id].url,
          url: this.pattern_type_map[1].url,
          pattern: this.sensitiveForm.pattern,
          test_data: this.sensitiveForm.test_data,
        })
        this.loadingDone()
        if (res.status === 201) {
          if (res.data.data === '') {
            this.$message.warning(
              this.$t('views.sensitiveManage.noValidatio') as string
            )
          }
          this.validatioData = res.data.data
        } else {
          this.$message.error(res.msg)
        }
      }
    })
  }

  private async getPatternType() {
    const res = await this.services.setting.pattern_type()
    if (res.status === 201) {
      this.pattern_type = res.data
      this.pattern_type.forEach((item: any) => {
        this.$set(this.pattern_type_map, item.id, {
          name: item.name,
          url: item.url,
        })
      })
    } else {
      this.$message.error(res.msg)
    }
  }
}
